@import '../../bootstrap-theme';

.site-header{
  background-color: c(white);
  position: relative;
  z-index: 3;

  .navbar {
    padding: 0;
  }

  .site-branding {
    @include make-col-ready;
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }
  }

  .navbar-brand {
    @include rem(font-size, 20px);
    @include rem(padding, 17.32px 0);
    @include rem(margin, 0);
    font-family: $font-barlow-semi-condensed;
    white-space: normal;

    @include media-breakpoint-up(md) {
      @include rem(font-size, 30px);
    }
  }

  .logo-link {
    position: relative;
    display: block;
    padding-left: 100px;
    padding-right: 50px;
    a{
      color: c(dune, light);
      &:hover {
        color: c(dune, light);
      }
    }
  }

  .site-name {
    display: block;
    text-indent: -999px;
    overflow: hidden;
    width: 0;
    height: 0;
  }

  .site-logo {
    @include rem(width, 100px);
    height: auto;
    margin: 0;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .site-slogan {
    @include rem(padding, 5px 0 5px $grid-gutter-width/2);
    @include rem(margin, 0 0 0 $grid-gutter-width/2);
    border-left: 3px solid c(dune, light);
    display: block;
    vertical-align: middle;
    line-height: 1.1;
  }

  .navbar-toggler {
    //@include vertical-center;
    position: absolute;
    top: 10px;
    @include rem(right, $grid-gutter-width/2);
    @include rem(width, 40px);
    @include rem(height, 40px);
    padding: 0;
    color: c(glacier);

    .navbar-toggler-icon {
      @include absolute-center;
      height: auto;
      width: auto;
    }

    .navbar-open {
      display: inline-block;
      visibility: visible;
    }

    .navbar-close {
      @include remove;
    }

    &.collapsed {

      .navbar-open {
        @include remove;
      }

      .navbar-close {
        display: inline-block;
        visibility: visible;
      }
    }
  }

  .navbar-collapse {
    @include make-col-ready;
    @include make-col(12);
    position: absolute;
    top: 66px;
    background: #ffffff;
    @include rem(padding-bottom, 25px);

    @include media-breakpoint-up(sm) {
      @include make-col(6);
      position: relative;
      top: auto;
      @include rem(padding-bottom, 0px);
    }

    .navbar-nav {
      @include no-bullets;
      margin: 0;
      text-align: center;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      @include media-breakpoint-up(sm) {
        text-align: right;
      }

      .nav-item {
        @include flex(0 0 auto);
        display: block;
        @include rem(padding, 10px);

        @include media-breakpoint-up(sm) {
          display: inline-block;
        }

        @include media-breakpoint-between(sm, md) {
          @include rem(margin-left, 10px);
        }

        @include media-breakpoint-up(md) {
          @include rem(margin-left, 15px);
        }

        &:first-child {
          margin: 0;
        }
      }

      .nav-link {
        @include rem(font-size, 16px);
        color: c(orient, medium);
        display: block;
        font-family: $font-barlow-semi-condensed;
        text-transform: uppercase;
        font-weight: 500;
        transition: all 0.2s ease-in;

        @include media-breakpoint-up(sm) {
          @include rem(padding, 25px 0);
          padding-left: 0;
          padding-right: 0;
        }

        &[title="Download the Report"]{
          @include rem(padding, 4px 6px);
          color: c(glacier);
          border: 1px solid c(glacier);

          &:hover {
            color: c(orient, medium);
          }
        }

        &:hover {
          color: c(gray, dark);
        }
      }
    }
  }

}