@import '../../bootstrap-theme';

.data-point-highlight {
  text-align: center;
  @include rem(margin-bottom, 20px);
}

.data-point {
  @include rem(font-size, 24px);
  @include rem(width, 225px);
  @include rem(height, 225px);
  @include rem(padding-top, 30px);
  font-family: $font-barlow;
  display: inline-block;
  border-radius: 50%;
  line-height: 1.25;
  background-color: c(prussian-blue, fifty);

  .value {
    @include rem(font-size, 72px);
    font-family: $font-barlow-semi-condensed;
    font-weight: 300;
  }

  .unit {
    font-weight: bold;
    text-transform: uppercase;
  }

  .label {
    color: c(white);
  }
}