//Breakpoints
$screen-xs:             0;
$screen-sm:             768px;
$screen-md:             992px;
$screen-lg:             1200px;
$screen-xl:             1400px;

$screen-xs-max:         $screen-sm - 1;
$screen-sm-max:         $screen-md -1;
$screen-md-max:         $screen-lg - 1;
$screen-lg-max:         $screen-xl - 1;

$screen-sm-min:         $screen-xs + 1;
$screen-md-min:         $screen-sm + 1;
$screen-lg-min:         $screen-md + 1;
$screen-xl-min:         $screen-lg + 1;


//TYPOGRAPHY
$base-fontsize:                 16;
$font-barlow:                   'Barlow', sans-serif;
$font-barlow-semi-condensed:    'Barlow Semi Condensed', sans-serif;
$font-barlow-condensed:         'Barlow Condensed', sans-serif;
$font-libre-baskerville:        'Libre Baskerville', serif;


//
// COLOR PALETTE
//
// These are the colors you will use for design elements throughout the site.
// You can set the keys to whatever you like. For example, keys like "1",
// "blue", or "blue-1" will all work.
//
// Each key has sub-keys to reflect the variants of a color. The only sub-key
// that is required is "base". You can add sub-keys for additional variants
// of your colors.
//
// Access color palette values via c() function. See _functions.scss
//
// Examples:
//    Access base color:
//      color: c(1);
//      color: c(blue);
//      background: linear-gradient(45deg, c(1), c(blue));
//
//    Accessing variants:
//      color: c(1, dark);
//      color: c(blue, light);
//      background: linear-gradient(45deg, c(1, light), c(blue, dark));
//
// Color Names: http://chir.ag/projects/name-that-color
//
$c-palette: (
  // Example 1
  // primary: (
  //   base: $bermuda-grey,
  //   dark: $dark-bermuda-grey,
  //   light: $light-bermuda-grey
  // ),
  // Example 2
  // 2: (
  //   base: $emerald,
  //   dark: darken($emerald, 10%),
  //   light: lighten($emerald, 10%)
  // ),
  // Example 3
  // persian-red: (
  //   base: hsla(0, 64%, 51%, 1),
  //   dark: hsla(0, 64%, 35%, 1),
  //   transparent: hsla(0, 64%, 35%, .5),
  // ),
  // Your turn!
  // key: (
  //   base: ,
  //   dark: ,
  //   light: ,
  //   some-other-variant: ,
  // ),
        white: (
                base: hsla(0, 0%, 100%, 1) //#FFF
        ),
        black: (
                base: hsla(0, 0%, 0%, 1) //#000
        ),
        gray: (
                base: hsla(0, 0%, 50%, 1), //#808080
                dark: hsla(0, 0%, 25%, 1), //#404040
                light: hsla(0, 0%, 90%, 1) //#E6E6E6
        ),
        onahau: (
                base: hsla(198, 100%, 91%, 1) //#D3F2FF
        ),
        glacier: (
                base: hsla(197, 33%, 63%, 1) //#80ADBF
        ),
        cornflower-blue: (
                base: hsla(197, 33%, 63%, 1) //#82AEC0
        ),
        bondi-blue: (
                base: hsla(194, 100%, 37%, 1), //#0091BD
                dark: hsla(194, 99%, 37% ,1) //#0190BC
        ),
        bahama-blue: (
                base: hsla(195, 99%, 30%, 1) //#017298
        ),
        orient: (
                light: hsla(196, 100%, 27%, 1), //#00658A
                base: hsla(197, 100%, 25%, 1), //#005B80
                medium: hsla(197, 100%, 25%, 1), //#00587C
                dark: hsla(197, 100%, 24%, 1) //#00587A
        ),
        astronaut-blue: (
                base: hsla(201, 100%, 18%, 1), //#003C5C
                dark: hsla(200, 100%, 18%, 1) //#003D5C
        ),
        prussian-blue: (
                base: hsla(203, 100%, 14%, 1), //#002C47
                transparent: hsla(203, 100%, 14%, .1), //#002C47
                fifty: hsla(203, 100%, 14%, .5) //#002C47
        ),
        blue-charcoal: (
                base: hsla(206, 100%, 1%, 1) //#000305
        ),
        dune: (
                light: hsla(30, 13%, 18%, 1), //#332D27
                base: hsla(30, 13%, 18%, 1) //#342E28
        )
);