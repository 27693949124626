@import '../../bootstrap-theme';

.costs {
  @include rem(max-width, 900px);
  margin: 0 auto;
}

.cost {
  @include make-col-ready;
  @include make-col(12);
  @include rem(margin-bottom, 40px);

  @include media-breakpoint-up(sm) {
    @include make-col(6);
    @include rem(margin-bottom, 2s0px);
  }
}

.state-fact-sheet{
  text-align: center;
  text-transform: uppercase;
}