@import '../../bootstrap-theme';

.site-footer {
  text-align: center;
  position: relative;
  z-index: 1;

  .copyright {
    @include make-col-ready;
    @include make-col(12);

    p {
      @include rem(margin-top, 380px);
      @include rem(margin-bottom, 100px);
      @include rem(font-size, 16);
      font-family: $font-barlow;

      @include media-breakpoint-between(sm, md) {
        @include rem(margin-bottom, 200px);
      }

      @include media-breakpoint-between(md, lg) {
        @include rem(margin-bottom, 140px);
      }
    }

    a {}
  }
}