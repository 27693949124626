@import '../../bootstrap-theme';

.make-big-oil-pay{

}

h2.share-heading{
  text-transform: uppercase;
  @include rem(font-size, 16px);
  @include rem(margin-top, 50px);
  font-weight: 700;
}

.share-arrow{
  i{
    @include rem(height, 45px);
    @include rem(width, 45px);
    @include rem(font-size, 30px);
    border: 3px solid c(onahau);
    border-radius: 50%;
    &:before{
      position: relative;
      @include rem(top, 4px);
    }
  }
}

.shareImage{
  @include rem(margin, -70px 0 -65px 0);
  position: relative;
  z-index: 1;
  background-image: url(../../images/share_bg.png);
  background-size: cover;
  .row{
    @include rem(margin-bottom, 40px);
    @include rem(margin-top, 20px);
    @include media-breakpoint-up(sm) {
      @include rem(margin-top, -45px);
    }
  }
  .costs{
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }
  }
}

.shareImage-title{
  text-align: center;
  @include rem(font-size, 30px);
  line-height: 1;
  strong{
    display: block;
  }
  @include media-breakpoint-up(sm) {
    @include rem(font-size, 72px);
  }
}

.shareIcons{
  text-align: center;
  @include rem(margin, 30px 0);
  position: relative;
  z-index: 2;
  i{
    display: inline-block;
    @include rem(height, 45px);
    @include rem(width, 45px);
    @include rem(margin, 0 12px);
    @include rem(font-size, 18px);
    border-radius: 50%;
    background: c(bahama-blue);
    &:before{
      position: relative;
      @include rem(top, 12px);
    }
  }
}