@import '../../bootstrap-theme';
@import 'animate';


*,
*:before,
*:after {
  box-sizing: border-box;
}
*:focus {
  outline: #fff 1px solid;
}
html,
body {
  min-height: 100%;
  min-height: 100vh;
}
body {
  font-size: $font-size-base;
  line-height: 1;
  font-family: $font-barlow;
  position: relative;
  color: c(white) !important;
  background-color: c(orient, medium) !important;
  background-image: url('../../images/stop-sign.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;

  &:before {
    content: "";
    display: block;
    background-image: url('../../images/side-measurement-ticks.png');
    background-position: right top;
    background-repeat: repeat-y;
    background-size: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 10px;
    z-index: 1;
  }
}

//Headings
@include headings() {
  font-family: $font-barlow-semi-condensed;
}

h1,
.h1 {

}
h2,
.h2 {}
h3,
.h3 {}
h4,
.h4 {}
h5,
.h5 {}
h6,
.h6 {}

//Content Elements
main {
  position: relative;
  z-index: 2;
}
a {
  cursor: pointer;
  color: c(white);
  &:hover {}
}
button {
  // Don't style button elements, since they're often styled as links for
  // accessible widgets. Use the .button class in components/buttons.
  cursor: pointer;
}
p,
li,
dt,
dd,
code,
address {}
%blockquote,
blockquote {}
nav {}
article {}
aside {}
section {

}
cite {}
del {}
code {}
address {}
details {}
dfn {}
hr {}
strong,
b {}
small {}
em,
i {}
header {}
footer {}

//Tables
table {
  width: 100%;
}
thead {
  th {
    @include rem(font-size, 24px);
    border-bottom: 2px solid c(glacier);
    line-height: 1.25;
  }
}

tbody {
  th,
  td {
    padding-top: $grid-gutter-width/2;
    border-bottom: 1px solid c(glacier);
    line-height: 1.25;
    &:last-child{
      text-align: right;
    }
  }
}
tr {}

tfoot {}
col {}
colgroup {}

.table-responsive > .table > thead > tr > th,
.table-responsive > .table > thead > tr > td,
.table-responsive > .table > tbody > tr > th,
.table-responsive > .table > tbody > tr > td,
.table-responsive > .table > tfoot > tr > th,
.table-responsive > .table > tfoot > tr > td {
  @media only screen and (max-width: $screen-xs-max) {
    white-space: normal;
  }
}

//Lists
ol {}
ul {}
li {}
datalist {}
dl {}
dt {}
dd {}

//Media
img{
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
svg {}
figure {
  margin: 0;
}
caption {}
embed {}
object {}
iframe {}
audio {}
canvas {}

//Forms
form {}
fieldset {}
legend {}
input {}
select {}

.sea-level-wrapper{
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.sea-level,
.sea-level-wave{
  background-color: c(prussian-blue, transparent);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.sea-level {
  transition: height .3s ease-in-out;
  transition-delay: 1s;
  height: 0;
  .sea-level-1 &{
    height: calc(100% - 800px);
  }
  .sea-level-2 &{
    height: calc(100% - 700px);
  }
  .sea-level-3 &{
    height: calc(100% - 600px);
  }
  .sea-level-4 &{
    height: calc(100% - 400px);
  }
  .sea-level-5 &{
    height: calc(100% - 200px);
  }
}

.sea-level-wave {
  transition: height .4s ease-in-out;
  transition-delay: 1s;
  height: 0;
  .sea-level-1 &{
    height: calc(100% - 825px);
  }
  .sea-level-2 &{
    height: calc(100% - 725px);
  }
  .sea-level-3 &{
    height: calc(100% - 625px);
  }
  .sea-level-4 &{
    height: calc(100% - 425px);
  }
  .sea-level-5 &{
    height: calc(100% - 225px);
  }

  &:before {
    @include rem(height, 20px);
    content: "";
    display: block;
    background-image: url('../../images/sea-level-wavy.png');
    background-position: center center;
    background-repeat: repeat-x;
    background-size: cover;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
  }
}

.page-title {
  @include rem(margin, 30px 0 35px);
  @include rem(font-size, 48px);
  @include rem(padding, 0 15px 0 25px);
  font-weight: 700;
  text-align: center;
  line-height: 1.1;

  @include media-breakpoint-up(sm) {
    @include rem(font-size, 76px);
    @include rem(padding,  0 80px 0 80px );
  }

  @include media-breakpoint-up(md) {
    @include rem(font-size, 76px);
    @include rem(padding, 0 100px 0 100px  );
  }
}

.intro-text{
  @include rem(font-size, 20px);
  @include rem(margin, 15px auto 30px);
  @include rem(max-width, 930px);
  color: c(onahau);
  line-height: 1.5;
  position: relative;
  z-index: 2;
  text-align: center;
  .home &{
    @include media-breakpoint-up(sm) {
      @include rem(padding, 0 40px);
    }
  }

}

.cost-link {
  @include rem(font-size, 15);
  font-family: $font-barlow-semi-condensed;
  text-transform: uppercase;
  white-space: nowrap;
  color: c(glacier);
}

//content region for long text pages. See about page.
.text-content{
  @include transparent(#fff, 1);
  @include rem(padding, 30px);
  max-width: 800px;
  margin: 0 auto;
  color: #444;
  border-radius: 4px;
  a{
    color: c(orient, medium);
    text-decoration: underline;
    &:hover{
      color: c(orient, dark);
    }
  }
  table{
    @include rem(margin-bottom, 30px);
    tbody{
      td{
        &:last-child{
          text-align: left;
        }
      }
    }
  }
}

.label{
  color: c(glacier);
  text-transform: uppercase;
  font-family: $font-barlow;
  @include rem(font-size, 14px);
  font-weight: 400;
}

.buttons{//two buttons in a row
  text-align: center;

  .button {
    display: block;
    margin:  10px auto;
    width: 100%;
    max-width: 260px;
   
    @include media-breakpoint-up(sm) {
      display: inline-block;
      &:first-child {
        @include rem(margin, 0 15px 0 0);
      }
      &:last-child {
        @include rem(margin, 0 0 0 15px);
      }
    }
  }

  /*.button:first-child{
    @include rem(margin-right, 15px);
  }
  .button:lastchild{
    @include rem(margin-left, 15px);
  }*/
}
.button{
  appearance: none;
  display: inline-block;
  padding: $grid-gutter-width/4 $grid-gutter-width/2;
  border: 3px solid c(bondi-blue);
  background: transparent;
  color: c(white);
  font-family: $font-barlow-semi-condensed;
  @include rem(font-size, 18px);
  text-align: center;
  text-transform: uppercase;
  transition: background .3s ease-in-out;
  &.centered{
    display: block;
    max-width: 280px;
    margin: $grid-gutter-width auto;
  }
  &:hover{
    text-decoration: none;
    background: c(astronaut-blue);
    transition: background .3s ease-in-out;
  }
}

.container h2 {
  font-size: 1.5rem;
  line-height: 1.1;

  @include media-breakpoint-up(sm) {
    font-size: 2.5rem;
  }
}

main .container {
  padding-left: 25px;
  padding-right: 15px;
  @include media-breakpoint-up(sm) {
    padding-left: 15px;
  }
}