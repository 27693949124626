@import '../../bootstrap-theme';

.icons-listing{
  list-style: none;
  margin: 0;
  @include rem(margin, 30px 0);
  padding: 0;
  li{
    @include rem(margin-bottom, 20px);
    @include rem(padding-top, 4px);
    @include rem(padding-left, 80px);
    @include rem(min-height, 60px);
    position: relative;
    color: c(onahau);
    &:before{
      @include rem(width, 50px);
      @include rem(height, 50px);
      @include rem(top, 0);
      @include rem(left, 0);
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
    }
  }
}

.icon-schools:before{
  background-image: url('../../images/icon-schools.svg');
}
.icon-law_enforcement:before{
  background-image: url('../../images/icon-law_enforcement.svg');
}
.icon-hospitals_healthcare:before{
  background-image: url('../../images/icon-hospitals_healthcare.svg');
}
.icon-flooded_homes:before{
  background-image: url('../../images/icon-flooded_homes.svg');
}
.icon-damaged_ecosytems:before{
  background-image: url('../../images/icon-damaged_ecosytems.svg');
}
.icon-death_displacement:before{
  background-image: url('../../images/icon-death_displacement.svg');
}
.icon-closed:before{
  background-image: url('../../images/icon-closed.svg');
}
