@import '../../bootstrap-theme';


.search-wrapper {
  @include rem(width, 500px);
  @include rem(margin, 0 auto 30px);
  max-width: 100%;
}

.search {

  position: relative;//for icon positioning inside

  label {
    @include rem(font-size, 15px);
    color: c(white);
    font-family: $font-barlow-semi-condensed;
  }

  .fa-search{
    position: absolute;
    display: block;
    @include rem(height, 40px);
    @include rem(width, 40px);
    @include rem(top, 36px);
    @include rem(right, 4px);
    border: 2px solid c(bondi-blue);
    border-radius: 50%;
    &:before{
      @include rem(top, 8px);
      @include rem(font-size, 20px);
      position: relative;
      color: c(bondi-blue);
    }
  }

}

.react-autosuggest__container {
  .location-type{
    @include rem(font-size, 10px);
    color: c(gray);
    text-transform: uppercase;
  }
}

.react-autosuggest__input {
  @include rem(font-size, 18px);
  @include rem(max-width, 540px);
  @include rem(padding, 12px 9px);
  font-family: $font-barlow;
  width: 100%;
  border: none;
}

.react-autosuggest__suggestion{

  &--highlighted{
    a{
      background: c(gray, light);
      text-decoration: none;
    }
  }
}

.react-autosuggest__suggestions-container {
  @include rem(max-width, 540px);
  @include rem(font-size, 20px);
  margin: 0 auto;
  text-align: left;
  background: c(white);

  ul {
    @include no-bullets;

    li {}

    a {
      @include rem(font-size, 16px);
      @include rem(padding, 3px 9px);
      display: block;
      color: c(black);
      &:hover{
        background: c(gray, light);
        text-decoration: none;
      }
    }
  }
}