@import '../../bootstrap-theme';


.ticker {
  @include rem(padding, 10px 0);
  background-color: c(astronaut-blue);
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;

  .row {
    @include flex-wrap(nowrap);
  }
}

.ticker-item {
  @include make-col-ready;
  @include make-col(12);
  @include rem(font-size, 20px);
  @include rem(margin, 0 10px);
  @include rem(padding, 8px 40px);
  max-width: calc(100% - 20px);
  border: 2px solid c(bondi-blue, dark);
  color: c(bondi-blue, dark);
  font-family: $font-barlow-semi-condensed;
  line-height: 1.25;
  text-align: center;
  transition: margin 700ms;

  @include media-breakpoint-up(sm) {
    @include make-col(4);
    max-width: calc(33.33333% - 20px);
  }

  &.active {
    color: c(white);
  }

  .cost-link {
    @include rem(font-size, 16px);
    color: c(bondi-blue, dark);
    display: block;
    cursor: pointer;
  }
}


.ticker-nav {
  @include cover;
  pointer-events: none;

  button {
    @include rem(width, 45px);
    @include rem(height, 45px);
    @include vertical-center;
    background-color: c(astronaut-blue);
    border: 2px solid c(bondi-blue, dark);
    border-radius: 50%;
    color: c(bondi-blue, dark);
    pointer-events: all;
  }

  .previous {
    @include rem(left, 5px);

    @include media-breakpoint-up(sm) {
      left: calc(33.33333% - 25px);
    }
  }

  .next {
    @include rem(right, 5px);

    @include media-breakpoint-up(sm) {
      right: calc(33.33333% - 25px);
    }
  }
}