@import '../../bootstrap-theme';

.big-stat{
  @include make-col(12);
  @include rem(margin-bottom, 40px);
  @include media-breakpoint-up(sm) {
    @include make-col(5);
    @include rem(margin-bottom, 0);
  }
}

.small-stat{
  @include make-col(12);

  .data-point{
    @include rem(width, 155px);
    @include rem(height, 155px);
    @include rem(font-size, 18px);
    

    @include media-breakpoint-up(sm) {
      float: left;
      @include rem(margin-left, 18px);
    }
  }

  .value{
    @include rem(font-size, 52px);
  }

  p{
    clear: left;
    @include rem(padding, $grid-gutter-width $grid-gutter-width 0 $grid-gutter-width );

    @include media-breakpoint-up(sm) {
      @include rem(padding-top, $grid-gutter-width);
    }
  }

  @include media-breakpoint-up(sm) {
    @include make-col(7);
    @include rem(padding-left, $grid-gutter-width);
  }

}