//////////////////////////////////////////
//CONTENT
//////////////////////////////////////////

//Clearfix
@mixin clearfix {
  @if support-legacy-browser('ie', '6', '7') {
    *zoom: 1;
  }
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}
%clearfix {
  @include clearfix;
}

//Screenreaders Only
@mixin sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
@mixin sr-only-focusable {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  &:focus{
    position: static !important;
    clip: auto;
    overflow: visible;
    height: auto;
  }
}


//Hide Element
@mixin hide {
  visibility: hidden;
  opacity: 0;
}

.hide {
  @include hide;
}

//Show Element
@mixin show {
  visibility: visible;
  opacity: 1;
}

.show {
  @include show;
}

//Remove Element
@mixin remove {
  visibility: hidden;
  display: none;
}


//Replace Element
@mixin replace {
  visibility: visible;
  display: initial;
}

.remove {
  @include remove;
}

//Move Offscreen
@mixin offscreen {
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.offscreen {
  @include offscreen;
}


//Absolute Positioned horizontal Centering
@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
}


//Absolute Positioned Vertical Centering
@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  -webkit-transform: translate(0, -50%);
}


//Absolute Positioned Centering
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

// Em conversion
// Convert pixel values to em.
@function em($px, $base: $base-font-size) {
  @return ($px / $base) * 1em;
}

//Ultimate REM Mixin
//https://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/

@function parseInt($n) {
  /* 2 */
  @return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
  $px: (); /* 3 */
  $rem: (); /* 3 */

  @each $value in $values {
    /* 4 */

    @if $value == 0 or $value == auto {
      /* 5 */
      $px: append($px, $value);
      $rem: append($rem, $value);
    } @else {
      $unit: unit($value); /* 6 */
      $val: parseInt($value); /* 6 */

      @if $unit == 'px' {
        /* 7 */
        $px: append($px, $value);
        $rem: append($rem,  ($val / $base-fontsize + rem));
      }

      @if $unit == 'rem' {
        /* 7 */
        $px: append($px,  ($val * $base-fontsize + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    /* 8 */
    #{$property}: $px; /* 9 */
  } @else {
    #{$property}: $px; /* 9 */
    #{$property}: $rem; /* 9 */
  }
}

//Heading Type Scale
@mixin create-heading-type-scale($scale) {

  // Check the $scale value and store in a variable to be
  // used when calculating the font sizes.
  $the-heading-type-scale: check-type-scale-value($scale);

  // Starting from h6, multiply each previous value by the scale
  // to get the next font size
  $font-size-h6 : $font-size-base;
  $font-size-h5 : $font-size-h6 * $the-heading-type-scale;
  $font-size-h4 : $font-size-h5 * $the-heading-type-scale;
  $font-size-h3 : $font-size-h4 * $the-heading-type-scale;
  $font-size-h2 : $font-size-h3 * $the-heading-type-scale;
  $font-size-h1 : $font-size-h2 * $the-heading-type-scale;
  // $font-size-display-base is made global to allow for accessing the
  // varibale in the next mixin.
  $font-size-display-base : $font-size-h1 !global;

  // Add the created font sizes to the elements and classes
  h1, .h1 { font-size: $font-size-h1; }
  h2, .h2 { font-size: $font-size-h2; }
  h3, .h3 { font-size: $font-size-h3; }
  h4, .h4 { font-size: $font-size-h4; }
  h5, .h5 { font-size: $font-size-h5; }
  h6, .h6 { font-size: $font-size-h6; }
}

//Display Type Scale
@mixin create-display-type-scale($scale) {

  // Store default type scale in a variable for calculations
  $the-display-type-scale: check-type-scale-value($scale);

  // Create variables to reference the previous font size
  $font-size-display-4 : $font-size-display-base + $font-size-base;
  $font-size-display-3 : $font-size-display-4 * $the-display-type-scale;
  $font-size-display-2 : $font-size-display-3 * $the-display-type-scale;
  $font-size-display-1 : $font-size-display-2 * $the-display-type-scale;

  // Add the created font sizes to the elements and classes
  .display-4 { font-size: $font-size-display-4; }
  .display-3 { font-size: $font-size-display-3; }
  .display-2 { font-size: $font-size-display-2; }
  .display-1 { font-size: $font-size-display-1; }
}


// Break Out Container
@mixin breakout() {
  margin-left: calc((100vw - 100%) / (0 - 2));
  width: 100vw;
}

//Single Line Ellipses
@mixin truncate($width) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Multiline Ellipses
@mixin multiline-ellipsis($font-size: 1, $lineHeight: 1.25, $lineCount: 1, $context: 16px, $bgColor: transparent){
  overflow: hidden;
  //position: relative;
  display: block;
  display: -webkit-box;
  line-height: $lineHeight;
  max-height: $font-size * $lineHeight * $lineCount * $context;
  max-width: 100%;
  //text-align: justify;
  //margin-right: -1em;
  // padding-right: 1em;
  //padding-right: $gutter-width*1.2;
  font-size: $font-size + em;
  -webkit-line-clamp: $lineCount;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  //&:before {
  //  //content: '\02026';
  //  content: '...';
  //  position: absolute;
  //  //right: 0;
  //  right: $gutter-width/2;
  //  bottom: $slug-height/20;
  //}
  //&:after {
  //  content: '';
  //  position: absolute;
  //  //right: 0;
  //  right: $gutter-width/2;
  //  width: 1em;
  //  height: 1em;
  //  margin-top: 0.2em;
  //  background: $bgColor;
  //}
}

//Remove Multiline Ellipsis
@mixin remove-ellipsis {
  max-height: none;
  -webkit-line-clamp: initial;
  text-overflow: initial;
}

//Cover Element
@mixin cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

//Gradient
//@mixin vertical-gradient($start-color, $start-opacity, $end-color, $end-opacity, $start-position: 0%, $end-position: 100%) {
//  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
//  background: -moz-linear-gradient(top,  rgba($start-color, $start-opacity) $start-position, rgba($end-color, $end-opacity) $end-position); /* FF3.6-15 */
//  background: -webkit-linear-gradient(top,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position); /* Chrome10-25,Safari5.1-6 */
//  background: -ms-linear-gradient(top,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
//  background: linear-gradient(to bottom,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=0 ); /* IE6-9 */
//}

@mixin gradient($start-color, $start-opacity, $end-color, $end-opacity, $orentation: vertical, $start-position: 0%, $end-position: 100%) {
  @if $orentation == vertical {
    background: -moz-linear-gradient(top,  rgba($start-color, $start-opacity) $start-position, rgba($end-color, $end-opacity) $end-position);
    background: -webkit-linear-gradient(top,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: -ms-linear-gradient(top,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: linear-gradient(to bottom,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=0 );
  } @else if $orentation == horizontal {
    background: -moz-linear-gradient(left,  rgba($start-color, $start-opacity) $start-position, rgba($end-color, $end-opacity) $end-position);
    background: -webkit-linear-gradient(left,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: -ms-linear-gradient(left,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: linear-gradient(to right,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 );
  } @else if $orentation == diagonalup {
    background: -moz-linear-gradient(45deg,  rgba($start-color, $start-opacity) $start-position, rgba($end-color, $end-opacity) $end-position);
    background: -webkit-linear-gradient(45deg,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: -ms-linear-gradient(45deg,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: linear-gradient(45deg,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 );
  } @else if $orentation == diagonaldown {
    background: -moz-linear-gradient(-45deg,  rgba($start-color, $start-opacity) $start-position, rgba($end-color, $end-opacity) $end-position);
    background: -webkit-linear-gradient(-45deg,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: -ms-linear-gradient(-45deg,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: linear-gradient(135deg,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 );
  } @else if $orentation == radial {
    background: -moz-radial-gradient(center, ellipse cover,  rgba($start-color, $start-opacity) $start-position, rgba($end-color, $end-opacity) $end-position);
    background: -webkit-radial-gradient(center, ellipse cover,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: -ms-linear-gradient(center, ellipse cover,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: radial-gradient(ellipse at center,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 );
  } @else {
    background: -moz-linear-gradient($orentation,  rgba($start-color, $start-opacity) $start-position, rgba($end-color, $end-opacity) $end-position);
    background: -webkit-linear-gradient($orentation,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: -ms-linear-gradient($orentation,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    background: linear-gradient($orentation,  rgba($start-color, $start-opacity) $start-position,rgba($end-color, $end-opacity) $end-position);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 );
  }
}

//Transparent Backgrounds
@mixin transparent($color, $alpha) {
    $rgba: rgba($color, $alpha);
    $ie-hex-str: ie-hex-str($rgba);
    background-color: transparent;
    background-color: $rgba;
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
    zoom: 1;
}


//Prevents elements from being selecteable (form elements, links, etc.)
//@include user-select(none);
@mixin user-select($select) {
  -webkit-touch-callout: $select;
  -webkit-user-select: $select;
  -khtml-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select;
  user-select: $select;
}


//Overlays
@mixin overlay {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
  }
}

@mixin transparent-color-overlay($color, $opacity) {
  @include overlay;
  .content:first-child {
    position: relative;
    z-index: 1;
  }
  &:before {
    @include transparent($color, $opacity);
  }
}

@mixin transparent-image-overlay($url, $opacity) {
  @include overlay;
  .content:first-child {
    position: relative;
    z-index: 1;
  }
  &:before {
    background-image: url($url);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center top;
    opacity: $opacity;
  }
}

@mixin gradient-overlay($start-color, $start-opacity, $end-color, $end-opacity, $orentation: vertical, $start-position: 0%, $end-position: 100%) {
  @include overlay;
  .content:first-child {
    position: relative;
    z-index: 1;
  }
  &:before {
    @include gradient($start-color, $start-opacity, $end-color, $end-opacity, $orentation, $start-position, $end-position);
  }
}




//////////////////////////////////////////
//TYPE
//////////////////////////////////////////

//Smallcaps
@mixin smallcaps() {
  // Some font-families don’t support small caps or don’t provide them with
  // their web font.
  font-variant: small-caps;
  text-transform: lowercase;
}


//Headings
/*USAGE
@include headings() {
    color: #000;
}
*/
@mixin headings($from: 1, $to: 6){
  @for $i from $from through $to{
    h#{$i}{
      @content
    }
  }
}

///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}




//////////////////////////////////////////
//LISTS
//////////////////////////////////////////

//Remove Bullets
@mixin no-bullets {
  list-style-type: none;
  list-style-image: none;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  ul{
    list-style-image: none;
    list-style-type: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  li{
    list-style-type: none;
    //margin-left: 0;
    //margin-right: 0;
    //padding-left: 0;
    //padding-right: 0;
    &.leaf{
      list-style-image: none;
      list-style-type: none;
    }
  }
}

//Remove List
@mixin no-list {
  @include no-bullets;
  li{
    display: inline-block;
  }
}

//Float List Items Left
@mixin float-list {
  @include no-bullets;
  li{
    float: left;
  }
}

//Restore List - No Bullets
@mixin  restore-list {
  @include no-bullets;
  li{
    display: list-item;
    float: none;
  }
}

//Make Bulleted List
@mixin make-list {
  ul{
    margin: 0 0 0 1.5em;
  }
  li{
    list-style: disc outside none;
    padding-left: 0;
  }
}

// Custom Lists
@mixin custom-list-style ($first-level-list-style-type:decimal, $second-level-list-style-type:lower-roman, $third-level-list-style-type:lower-alpha) {
  list-style: none;
  counter-reset: first-level-ol;
  overflow: hidden;
  zoom: 1;
  li{
    counter-increment: first-level-ol;
    &:before{
      //@include sans;
      //content: '.'counter(first-level-ol);//counter default is decimal
      content: counter(first-level-ol, $first-level-list-style-type);
      color: #333;
      display: inline-block;
      width: 1em;
      left: auto;
      font-weight: bold;
      margin-left: -22px;
      margin-right: 9px;
      text-align: right;
      direction: rtl;//right-to-left so the dot we wrote in the :before content will be on the right of the number and longer numbers will extend left from there. Remember: RTL numbers do not change order!
    }

    ul,
    ol {
      @include rem(padding-left, 32px);
      margin-bottom: 0;
     .typeset {
       @include rem(padding-left, 32px);
       margin-bottom: 0;
     }
    }

    //p{
    //  display: inline;
    //}
  }

  > ul,
  ol{
    counter-reset: second-level-ol;
    li {
      counter-increment: second-level-ol;
      &:before {
        //content: '.' counter(second-level-ol, lower-alpha);//small letters
        content: counter(second-level-ol, $second-level-list-style-type);
      }
    }
    > ul,
    ol{
      counter-reset: third-level-ol;
      li {
        counter-increment: third-level-ol;
        &:before {
          //content: '.' counter(third-level-ol, lower-roman);//small roman numerals
          content: counter(third-level-ol, $third-level-list-style-type);
        }
      }
    }
  }
}

@mixin custom-list-icon ($before-content:'', $width:.75rem, $height:.75rem, $top-position:.6875rem, $left-position:.625rem, $background-color:c(crimson), $border-radius:50%, $margin-bottom:0, $margin-right:0, $margin-left:0) {
  overflow: hidden;
  zoom: 1;

  .typeset & {
    padding-left: 0;
  }

  ul,
  ol {
    @include rem(padding-left, 32px);
    //padding-left: 0;
    margin-bottom: 0;

    .typeset & {
      @include rem(padding-left, 32px);
      //padding-left: 0;
      margin-bottom: 0;
    }
  }
  li {
    @include rem(padding-left, 32px);
    list-style: none;
    //padding-left: 24px;
    position: relative;

    .typeset & {
      @include rem(padding-left, 32px);
    }

    &:before {
      content: $before-content;
      width: $width;
      height: $height;
      position: absolute;
      top: $top-position;
      left: $left-position;
      background-color: $background-color;
      border-radius: $border-radius;
      vertical-align: middle;
      display: block;
      margin-bottom: $margin-bottom;
      margin-left: $margin-left;
      margin-right: $margin-right;
    }

  }
}



//////////////////////////////////////////
//FORMS
//////////////////////////////////////////

//Form Placeholders
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
  //&::-webkit-input-placeholder {@content}
  //&:-moz-placeholder           {@content}
  //&::-moz-placeholder          {@content}
  //&:-ms-input-placeholder      {@content}
}

//Custom Form Radio Buttons
//labels should be a direct sibling of radio input.  e.g.: <input type="radio"></input><label></label>
//include mixin on radio buttons.  e.g.: input[type="radio"] { @include custon-radio-buttons; }
@mixin custom-radio-buttons( $button-height: 15px, $button-width: 15px, $button-color: #ccc, $border-color: #999, $button-checked: #eee, $border-checked: #333, $border-width: 2px, $checked-border-width: 2px, $border-radius: 50%, $checked-border-radius: 50% ) {
  //@include vertical-center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  + label {
    position: relative;
    padding-left: $button-width + 5px;
    &:before {
      @include vertical-center;
      content: "";
      display: block;
      width: $button-width;
      height: $button-height;
      background-color: $button-color;
      border: $gray-border-1;
      border-color: $border-color;
      border-width: $border-width;
      border-radius: $border-radius;
      left: 0;
    }
  }
  &:checked + label:before {
    background-color: $button-checked;
    border-color: $border-checked;
    border-width: $checked-border-width;
    border-radius: $checked-border-radius;
    width: $checked-width;
    height: $checked-height;
  }
}


//////////////////////////////////////////
//MEDIA
//////////////////////////////////////////

// Inline SVG background image with a PNG fallback.
// Fallback based on: http://pauginer.tumblr.com/post/36614680636/invisible-gradient-technique
@mixin svg-background($image-name) {
  background-image: image-url("bg/" + $image-name + ".png", false, false);
  background-image: -webkit-linear-gradient(transparent, transparent), inline-image("bg/" + $image-name + ".svg");
  background-image: linear-gradient(transparent, transparent), inline-image("bg/" + $image-name + ".svg");
}

@mixin svgz-background($image-name) {
  background-image: image-url("bg/" + $image-name + ".png", false, false);
  background-image: -webkit-linear-gradient(transparent, transparent), image-url("bg/" + $image-name + ".svgz");
  background-image: linear-gradient(transparent, transparent), image-url("bg/" + $image-name + ".svgz");
}


//Responsive Video
@mixin responsive-video($aspect) {
  position: relative;
  height: 0;
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  @if $aspect == 'wide'{
    padding-bottom: 56.25%; /* 16:9 */
  } @else {
    padding-bottom: 75%; /* 4:3 */
  }
}


//Maintain Aspect Ratio
/*USAGE
--HTML--
<div class="some-element">
    <div class="content"></div>
</div>
--SASS--
.some-element { @include aspect-ratio(16, 9, '.inner-content-element'); }
*/
@mixin aspect-ratio($width, $height, $content:'.content') {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > #{$content} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

//Remove Aspect Ratio
@mixin remove-aspect-ratio($content) {
  position: relative;
  &:before {
    content: none;
  }
  > .#{$content} {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom:auto;
  }
}




