@import '../../bootstrap-theme';


.home {
  .intro-text{
    margin-top: 0;
    @include rem(max-width, 725px);
  }
}

.home-content {
  @include make-col-ready;
  @include make-col(12);
  @include rem(padding, 60px $grid-gutter-width/2);
  text-align: center;
  h2.home-title {
    @include rem(font-size, 72px);
    @include rem(margin, 0);
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
  
    @include media-breakpoint-down(sm) {
      @include rem(font-size, 52px);
    }
  }
}

.byline {
  @include rem(font-size, 20px);
  color: c(glacier);
  font-family: $font-barlow-semi-condensed;
  text-transform: uppercase;
}



.home-subtitle {
  @include rem(margin, 0 0 8px 0);
  @include rem(font-size, 72px);
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    @include rem(font-size, 52px);
  }
}