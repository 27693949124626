.jump-links {
   text-align: center;
   width: 100%;
   padding: 15px 0 30px 0;

   a {
     margin: 0 8px;
   }
   

}