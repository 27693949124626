@import '../../bootstrap-theme';

.data-point-fact {
  @include rem(margin-top, 12px);
  @include rem(font-size, 20px);
  line-height: 1.2;
  font-family: $font-barlow;

  .value {
    @include rem(font-size, 30px);
    font-weight: bold;
  }
  
  .label{
    @include rem(font-size, 24px);
    text-transform: none;
    color: c(white);
    a{
      text-decoration: underline;
    }
  }

  .unit {}

  .data-point + &{
    @include rem(margin-top, -54px);//pull up when following circled data point
  }
}