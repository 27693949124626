@import '../../bootstrap-theme';

.rankings-table{
  margin-bottom: 40px;

  .expand-table{
    cursor: pointer;
    color: c(white);
    margin-top: 8px;
    display: block;

    i{
      margin-right: 10px;
    }
  }

  tr:nth-child(1n + 11){
    display: none;
  }

  &.expanded{
    tr:nth-child(1n + 11){
      display: table-row;
    }
  }
}