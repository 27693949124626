@import '../../bootstrap-theme';

.related-data-wrapper{
  margin-top: $grid-gutter-width;
}

.related-data-content{
 @include rem(padding-bottom, ($grid-gutter-width * 1.5));

}

.related-data-image{

  @include rem(padding-top, $grid-gutter-width);
  @include rem(padding-bottom, ($grid-gutter-width * 1.5));

  .map-image{
    display: block;
    @include rem( width, 300px);
    margin: 0 auto;
  }

  img {
    display: block;
    width: 100%;
    @include rem(max-width, 300px);
    margin: 0 auto;
  }

  @include media-breakpoint-up(md) {
    @include rem(padding-bottom, 0px);
  }

}

.relatedData{
  tr:nth-child(1n + 6){
    display: none;
  }

  &.expanded{
    tr:nth-child(1n + 6){
      display: table-row;
    }
  }

  .expand-table{
    cursor: pointer;
  }
}